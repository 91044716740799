<template>
    <div id="home">
        <div class="title">Horagram Dev</div>
    
        <div>On this page you can find useful information and links for testing other pages.</div>
        
        <div class="router-link">
            Go to 
            <router-link to="/welcome">Welcome page</router-link>
        </div>
    </div>
</template>

<script>
import Header from "../components/header/Header.vue";
import StaticMenu from '../components/static-menu/StaticMenu.vue'
import AuthService from "../service/auth-service";
import CMS from "../service/cms/service";
import router from "../router";

export default {
    name: 'Home',
    components: {
        Header,
        StaticMenu
    },
    // mounted() {
    //     let code = this.$route.query.code
    //     if (code){
    //         AuthService.signIn({code, authMethod: 1})
    //             .then(response => {
    //                 if (response.data.err)
    //                     throw response.data.err
    //                 this.setupUserInfo({
    //                     firstName : response.data.firstName,
    //                     lastName : response.data.lastName,
    //                     email : response.data.email,
    //                 })
    //             })
    //     }
    // },

    methods: {
        // setupUserInfo(userData){
        //     CMS.user.getInfo()
        //         .then(({data, status}) => {
        //             if (data.err)
        //                 throw data.err
                    
        //             this.$store.dispatch("setup-geo")

        //             let isNewUser = status == 204
        //             if (isNewUser) {
        //                 this.newUserHandler(userData)
        //                 return
        //             }
                    
        //             let profile = data
        //             this.saveUserProfile(profile)

        //             let lastCalendarInLocalStorage = Boolean(localStorage.getItem("last-calendar"))
        //             if (lastCalendarInLocalStorage) {
        //                 router.push(localStorage.getItem("last-calendar"))
        //                 localStorage.removeItem("last-calendar")
        //                 return
        //             }

        //             router.push({name: 'calendar-list'}) // --> to calendar list
        //         })
        // },
        // newUserHandler(userData){
        //     this.$store.dispatch('update-user-data', {
        //         isLogin: true,
        //         firstName: userData.firstName,
        //         lastName: userData.lastName,
        //         email: userData.email,
        //     })
        //     router.push('welcome') // --> to welcome page
        // },
        
        // saveUserProfile(profile){
        //     let profileDate = {
        //         calendars: profile.calendars,
        //         firstName: profile.firstName,
        //         // no image url
        //         lastName: profile.lastName,
        //         shortName: profile.shortName,
        //         email: profile.email,
                
        //         countryId: profile.countryId,
        //         timezoneId: profile.timezoneId,
        //         workdayCalendarId: profile.workdayCalendarId,
        //     }

        //     this.$store.dispatch('update-user-data', {
        //         isLogin: true,
        //         ...profileDate
        //     })
        // },
        routerPush(name){
            router.push({name})
        }
    }
}
</script>

<style scoped>
#home{
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
</style>